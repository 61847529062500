import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer-links" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { "data-e2e": "footer-logged-in" }
const _hoisted_8 = { class: "social-links" }
const _hoisted_9 = { href: "#twitter_share" }
const _hoisted_10 = { class: "disclaimer" }
const _hoisted_11 = {
  key: 0,
  class: "artificial-tests"
}
const _hoisted_12 = ["textContent"]
const _hoisted_13 = ["src"]

import { computed } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useStore } from 'vuex';
    import { OutgoingAffiliate } from '@roc/affiliate';
    import { openPopup } from '../popup';

    
export default {
  __name: 'TheFooter',
  setup(__props) {

    const store = useStore();

    const { t, vT } = useI18next(['footer', 'links', 'global']);

    const profile = computed(() => store.state.profile);
    const app = computed(() => store.state.app);
    const loggedIn = computed(() => store.getters['profile/loggedIn']);

    const logOut = () => store.dispatch('profile/logOut');

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: `${app.value.tb2URL}public/fulfillment/rules.pdf`,
            target: "_blank"
          }, _toDisplayString(_unref(t)('links:rules_copy')), 9, _hoisted_3)
        ]),
        _createElementVNode("li", null, [
          _withDirectives((_openBlock(), _createElementBlock("a", {
            href: `${_ctx.$t('links:privacy_url')}`,
            title: `${_ctx.$t('links:privacy_copy')} ${_ctx.$t('new_window:pdf')}`,
            target: "_blank"
          }, [
            _createTextVNode(_toDisplayString(_unref(t)('links:privacy_copy')), 1)
          ], 8, _hoisted_4)), [
            [_unref(vT), 'links:privacy_copy']
          ])
        ]),
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: "/faq",
            title: `${_ctx.$t('links:faq_copy')} ${_ctx.$t('links:popup')}`,
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_unref(openPopup) && _unref(openPopup)(...args)), ["prevent"]))
          }, null, 8, _hoisted_5), [
            [_unref(vT), 'links:faq_copy']
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("li", { class: "break" }, null, -1)),
        (loggedIn.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, [
                _createTextVNode(" Logged in as " + _toDisplayString(profile.value.first_name) + " (Not you? ", 1),
                _createElementVNode("a", {
                  href: "/",
                  class: "btn_logout",
                  "data-e2e": "footer-logout",
                  onClick: logOut
                }, " Log Out "),
                _cache[1] || (_cache[1] = _createTextVNode(") "))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("ul", _hoisted_8, [
      _createElementVNode("li", null, [
        _createVNode(_unref(OutgoingAffiliate), {
          "affiliate-id": "facebook",
          target: "_blank"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "fab fa-facebook" }, null, -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("a", _hoisted_9, [
          _createVNode(_unref(OutgoingAffiliate), {
            "affiliate-id": "twitter",
            target: "_blank"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("i", { class: "fa-brands fa-x-twitter" }, null, -1)
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_10, null, 512), [
      [_unref(vT), { key: 'footer:disclaimer', tb2Url: app.value.tb2URL }]
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("p", { class: "disclaimer" }, [
      _createTextVNode(" This site is protected by reCAPTCHA and the Google "),
      _createElementVNode("a", {
        href: "https://policies.google.com/privacy",
        target: "_blank"
      }, "Privacy Policy"),
      _createTextVNode(" and "),
      _createElementVNode("a", {
        href: "https://policies.google.com/terms",
        target: "_blank"
      }, "Terms of Service"),
      _createTextVNode(" apply. ")
    ], -1)),
    (app.value.isAutomatedTest)
      ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
          _createElementVNode("span", {
            class: "test-copy",
            textContent: _toDisplayString(_unref(t)('testNamespace:testKey'))
          }, null, 8, _hoisted_12),
          _createElementVNode("img", {
            class: "language-flag",
            alt: "Language flag",
            src: _ctx.$i18nAsset('img/flag.svg'),
            style: {"display":"inline","width":"2em","padding":"0 0.5em","vertical-align":"middle"}
          }, null, 8, _hoisted_13)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}