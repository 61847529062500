import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "main-container" }
const _hoisted_3 = {
  id: "main-section",
  role: "main"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHeader = _resolveComponent("TheHeader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_TheFooter = _resolveComponent("TheFooter")
  const _component_CookieGate = _resolveComponent("CookieGate")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TheHeader),
      _createElementVNode("main", _hoisted_3, [
        _createVNode(_component_router_view, { class: "content" })
      ])
    ]),
    (_ctx.ui.pageName != 'faq')
      ? (_openBlock(), _createBlock(_component_TheFooter, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_CookieGate, {
      "body-copy": $setup.t('cookie_gate:body_copy', { tb2Url: _ctx.app.tb2URL }),
      "button-copy": $setup.t('cookie_gate:button_copy'),
      "title-copy": $setup.t('cookie_gate:title')
    }, null, 8, ["body-copy", "button-copy", "title-copy"])
  ]))
}