import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/modal-ornament-bell.png'
import _imports_1 from '@public/img/modal-ornament-diamond.png'


const _hoisted_1 = {
  class: "modal sweeps-confirm-modal",
  role: "dialog",
  "aria-label": "Sweepstakes Entry Confirmation"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "sweeps-confirm-modal-headline" }
const _hoisted_4 = { class: "sweeps-confirm-modal-copy" }
const _hoisted_5 = ["title", "aria-label"]
const _hoisted_6 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("img", {
        role: "presentation",
        class: "modal-ornament-bell",
        src: _imports_0
      }, null, -1)),
      _cache[5] || (_cache[5] = _createElementVNode("img", {
        role: "presentation",
        class: "modal-ornament-diamond",
        src: _imports_1
      }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("h2", _hoisted_3, null, 512), [
          [_directive_t, 'modal_content:sweeps_entry_confirmation.headline_copy']
        ]),
        _withDirectives(_createElementVNode("p", _hoisted_4, null, 512), [
          [_directive_t, 'modal_content:sweeps_entry_confirmation.body_copy']
        ]),
        _withDirectives(_createElementVNode("button", {
          class: "button sweeps-confirm-modal-continue",
          "data-e2e": "sweeps-confirm-modal-continue",
          title: $setup.t('buttons:lets_go'),
          "aria-label": $setup.t('button:lets_go'),
          type: "button",
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
        }, null, 8, _hoisted_5), [
          [_directive_t, 'buttons:lets_go']
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        style: {"opacity":"0"},
        "data-e2e": "sweeps-confirm-modal-close",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1),
        _createTextVNode(" Close ")
      ]), 8, _hoisted_6)
    ])
  ]))
}