import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/promo-logo-vertical.png'
import _imports_1 from '@public/img/promo-logo-horizontal.png'


const _hoisted_1 = {
  id: "header",
  role: "banner",
  "data-e2e": "global-header"
}
const _hoisted_2 = { class: "header-lockup" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  width: "512",
  height: "329",
  alt: "Roche Bros. Supermarket Holiday Instant Win Sweepstakes",
  class: "promo-logo-vertical"
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  width: "588",
  height: "127",
  alt: "Roche Bros. Supermarket Holiday Instant Win Sweepstakes",
  class: "promo-logo-horizontal"
}

import { ref, computed, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';

    
export default {
  __name: 'TheHeader',
  setup(__props) {

    const { t } = useI18next(['global']);
    const pagesWithVerticalLockup = new Set([
        'error',
        'expired',
        'ineligible-age',
        'limiter',
        'login',
        'maintenance',
        'prelaunch',
    ]);
    const store = useStore();

    // data
    const h1 = ref(null);

    // computed
    const hasVerticalLockup =
        computed(() => pagesWithVerticalLockup.has(store.state.ui.pageName));

    // Focus the h1 element to force screen readers back to the top after a navigation.
    // This will ensure screen readers will read the page again.
    // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
    onMounted(() => h1.value.focus());

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", {
      ref_key: "h1",
      ref: h1,
      tabindex: "-1"
    }, _toDisplayString(_unref(t)('global:title')), 513),
    _createElementVNode("div", _hoisted_2, [
      (hasVerticalLockup.value)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : (_openBlock(), _createElementBlock("img", _hoisted_4))
    ])
  ]))
}
}

}